import { useEffect } from 'react';

const useAppearOnScroll = (ref, delay = '0s') => {
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          entry.target.style.transitionDelay = delay;
          entry.target.classList.remove('opacity-0');
          entry.target.classList.add('opacity-100');
        } else {
          entry.target.style.transitionDelay = '0s';
          entry.target.classList.remove('opacity-100');
          entry.target.classList.add('opacity-0');
        }
      },
      {
        threshold: 0.1
      }
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref, delay]);
};

export default useAppearOnScroll;