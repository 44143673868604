import React, { useEffect, useRef, useState } from 'react';
import './App.css';
import backgroundImage from './Assets/websiteBackground.jpg';
import YtButton from './Content/YtButton';
import appIphone from './Assets/handOrbit.png';
import useAppearOnScroll from './Content/displayOnScroll';
import { HiOutlineRocketLaunch } from 'react-icons/hi2';
import {BsFillFilePersonFill} from 'react-icons/bs';
import spaceBG from './Assets/starBg.gif';
import ImageFader from './Content/imageCycle';
import orbitIphone from './Assets/orbitIphone.png';
import wristPhoto from './Assets/wristOrbit.jpeg';
import CircularProgress from './Content/circularProgress';
import spaceRoad from './Assets/spaceRoadMap.png';
import ReCAPTCHA from "react-google-recaptcha";


function App() {
  const [payload, setPayload] = useState(null);
  const [email, setEmail] = useState('');
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  


  const handleChange = (event) => {
    setEmail(event.target.value);
  };

  const setButtonTarget = (value) => {
    setIsButtonDisabled(value);
  }
  const [submitStatus, setSubmitStatus] = useState(null); // New state for submission status
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsButtonDisabled(true);
    try {
      const response = await fetch('/api/pushEmail', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok ' + response.statusText);
      }
      setEmail('');
      setSubmitStatus('Success! Check your email for further instructions.');
    } catch(error) {
      setSubmitStatus('Failed to submit. Please try again later.');
    }
  };

  const checkCaptcha = async (recaptchaValue) => {    
    try {
        const response = await fetch('/api/reCaptcha', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ recaptchaValue }),
        });

        const data = await response.json();
        console.log('Server Response:', data);
        setButtonTarget(false);
        // Optionally, handle the server response, e.g., by updating the UI

    } catch (error) {
        // Handle error, e.g., by updating the UI
    }
};
  
  const fetchPayload = async () => {
    try {
      const response = await fetch('/api/progress');
      //ERROR HERE
      const data = await response.json(); 

      // parse the response as JSON
      
      if (data.length > 0) { 
        // Check if there are any items in the array
        const lastItem = data[data.length - 1]; // Get the last item in the array
        const value = lastItem.percentageCount; 
        setPayload(value); // Set the calculated value to payload state
        return value; // Return the calculated value
      } else {
        console.warn('No data received from the API');
      }
  
    } catch (error) {
      console.error('There was an error fetching the payload:', error);
    }
    return 0; // Return 0 if there was an error or no data received
  };
  
  const handleButtonClick = async () => {
    const value = await fetchPayload(); // Fetch the payload and get the value
    
      setProgress(value); // Set the progress state with the fetched value
      setButtonClicked(true); // Set button as clicked

  };
  const [progress, setProgress] = useState(0); // Initialize progress to 0
  const [buttonClicked, setButtonClicked] = useState(false); 

  const imageDivRef = useRef(null);
  const cardDivRef = useRef(null); // Create a new ref for the card content

  const imageArray = [appIphone, orbitIphone, wristPhoto]
  
  // Use your custom hook for the image
  useAppearOnScroll(imageDivRef, '1s');
  useAppearOnScroll(cardDivRef, '1s');

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          entry.target.classList.remove('opacity-0');
          entry.target.classList.add('opacity-100');
        } else {
          entry.target.classList.remove('opacity-100');
          entry.target.classList.add('opacity-0');
        }
      },
      {
        threshold: 0.1
      }
    );

    // Observe both elements
    if (imageDivRef.current) {
      observer.observe(imageDivRef.current);
    }
    
    if (cardDivRef.current) {
      observer.observe(cardDivRef.current);
    }

    return () => {
      if (imageDivRef.current) {
        observer.unobserve(imageDivRef.current);
      }
      
      if (cardDivRef.current) {
        observer.unobserve(cardDivRef.current);
      }
    };
  }, []);

  return (
    <div className=" text-white min-h-screen font-pop text-center overflow-y-scroll">

      {/* Landing Section */}
      <section className="lex flex-col justify-center items-center bg-space-gray p-0 text-top font-pop grid place-items-center h-screen" 
      style={{
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  }} >
        <div className="w-full pb-xl pb-20">
          <h1 className="text-2xl sm:text-7xl font-semibold">Orbit: Smart Notifications</h1>
        </div>
        <YtButton />
      </section>

       {/* About Section */}
       <section 
  className="bg-black p-4 sm:p-6 md:p-10 text-center h-screen flex flex-col justify-start items-center"
  style={{
    backgroundImage: `url(${spaceBG})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  }}>
  <h2 className="text-2xl sm:text-4xl md:text-6xl font-semibold mb-8 sm:mb-10 md:mb-12 mt-4 sm:mt-8 md:mt-10">About</h2>
  <div className="flex flex-col md:flex-row mt-5 justify-center items-center space-y-5 md:space-y-0 md:space-x-16 lg:space-x-24">
    {/* Card Container */}
    <div ref={cardDivRef} className="opacity-0 transition-opacity duration-200 relative w-full md:w-1/2 lg:w-2/5 mx-2 md:mx-0">
      <div 
        className="absolute -inset-0.5 bg-gradient-to-r from-[#9945FF] to-[#e71353] rounded-lg filter blur-md opacity-75 group-hover:opacity-100 transition duration-800"
        style={{
          animation: 'breathing 2.5s infinite'
        }}
      ></div>
      <div className="relative flex flex-col justify-center items-center text-center bg-black rounded-lg p-8">
        <p className="text-base sm:text-lg md:text-xl">"A different perspective on notifications apps"</p>
        <HiOutlineRocketLaunch className="mt-2 text-xl md:text-2xl" />
        <p className="pt-2 text-base sm:text-lg md:text-xl">
          The world simply does revolve around <strong>YOU</strong>, and Orbit is here to help give you an extra nudge
        </p>
        <HiOutlineRocketLaunch className="mt-2 text-xl md:text-2xl" />
        <p className="pt-2 text-base sm:text-lg md:text-xl">
          Our development focuses on PRIVACY, SIMPLICITY, PRODUCTIVITY
        </p>
      </div>
    </div>
    {/* Image Container */}
    <div 
      ref={imageDivRef}
      className="max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg mx-2 md:mx-0 mt-5 md:mt-0 rounded-full shadow-lg overflow-hidden opacity-0 transition-opacity duration-500"
      style={{ aspectRatio: '1/1' }}
    >
      <ImageFader images={imageArray} interval={10000} />
    </div>
  </div>
</section>

{/* Roadmap Section */}
<section className="p-4 sm:p-10 h-screen flex flex-col items-center"
style={{
    backgroundImage: `url(${spaceRoad})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  }} >
    <h2 className="text-3xl sm:text-4xl font-semibold mb-4">Roadmap</h2>
    <div className="relative flex-grow flex items-center">
    <div className={buttonClicked ? " mr-5 flex flex-col items-center mr-4 transition-opacity duration-500 ease-in  opacity-100" : "opacity-0"}>

        {buttonClicked && 
          <div className="flex flex-col items-center mr-4 mb-12">
            <div className="border border-white p-4 sm:p-6 rounded-md"> 
              <div className="flex flex-col items-center mb-4 sm:mb-6">
                <div className="flex items-center mb-2">
                  <span className="text-3xl sm:text-4xl font-bold">1</span>
                  <BsFillFilePersonFill className="ml-2 text-2xl sm:text-3xl" />
                </div>
                <span className="text-base sm:text-lg">Developers</span>
              </div>
              <div className="flex flex-col items-center mb-4 sm:mb-6"> 
                <span className="text-xl sm:text-2xl text-black">Next Update</span>
                <span className="text-sm text-white">Beta V0.1.1</span>
              </div>
              <div className="flex flex-col items-center">
                <span className="text-3xl sm:text-4xl font-bold"># 10</span>
                <span className="text-base sm:text-lg">Bugs</span>
              </div>
            </div>
          </div>
        }
        </div>
        <div className="relative ml-4 sm:ml-10">
          { !buttonClicked && 
            <div className="relative group text-base sm:text-lg">
              <div className="absolute -inset-0.5 bg-gradient-to-r from-[#9945FF] to-[#e71353] rounded-full filter blur-md opacity-75 group-hover:opacity-100 transition duration-200 "></div>
              <button 
                onClick={handleButtonClick} 
                className="relative px-4 sm:px-7 py-2 bg-black rounded-full leading-none flex items-center divide-x divide-gray-600 transition-transform duration-300 ease-in-out transform"
                style={{ transform: buttonClicked ? 'scale(0.5)' : 'scale(1)' }}
              >
                <span className="flex items-center space-x-2 sm:space-x-4 text-gray-100">
                  Check Progress
                </span>
              </button>
            </div>
          }
    <div className={buttonClicked ? "transition-opacity duration-500 ease-in opacity-100" : "opacity-0"}>

          { buttonClicked && 
            <>
              <CircularProgress progress={progress} />
              <div className="absolute inset-0 flex flex-col justify-center items-center">
                <span className="text-5xl font-bold text-red-200">{progress}%</span>
                <span>Till</span>
                <span>Next Update</span>
              </div>
            </>
          }
          </div>
        </div>
    </div>
</section>


      {/* Email Drop Section */}
      <section className="bg-black p-20 text-center">
    <h2 className="text-4xl font-semibold">Pre Beta Sign-Up</h2>
    <form className="mt-8 flex flex-col items-center" onSubmit={handleSubmit}>
        <input 
            type="email" 
            placeholder="Your email" 
            className="p-2 rounded text-black mb-4" 
            value={email} 
            onChange={handleChange} 
        />
        <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            onChange={checkCaptcha}
            className="mb-4"  
        />
        <button 
            type="submit" 
            className={`p-2 rounded text-white ${isButtonDisabled ? 'bg-gray-400' : 'bg-rocket-red'}`} 
            disabled={isButtonDisabled}
        >
            Subscribe
        </button>
    </form>
    {submitStatus && (
        <p className="mt-4 text-white">{submitStatus}</p>
    )}
</section>
    </div>
  );
}
export default App;
