import React, { useState, useEffect } from 'react';

const ImageFader = ({ images, interval = 5000 }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, interval);

    return () => {
      clearInterval(timer);
    };
  }, [images, interval]);

  return (
    <div className="relative w-64 h-64 rounded-full overflow-hidden">
      {images.map((image, index) => (
        <img
          key={index}
          src={image}
          alt={`Slide ${index}`}
          className={`absolute w-full h-full object-cover transition-opacity ${currentIndex === index ? 'opacity-100' : 'opacity-0'}`}
          style={{ transitionDuration: '6500ms' }}
        />
      ))}
    </div>
  );
};

export default ImageFader;