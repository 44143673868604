function CircularProgress({ progress }) {
    const radius = 100;
    const circumference = 2 * Math.PI * radius;
    const offset = circumference - (progress / 100) * circumference;
  
    return (
      <svg className="mx-auto" height="220" width="220">
        <circle
          stroke="transparent"
          fill="#EBEBEB"
          cx="110"
          cy="110"
          opacity="0.20"
          r={radius}
        />
        <circle
          stroke="url(#gradient)"
          fill="transparent"
          strokeWidth="10"
          strokeDasharray={circumference}
          strokeDashoffset={offset}
          cx="110"
          cy="110"
          
          r={radius}
        />
        <defs>
          <linearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="0%">
            <stop offset="0%" stopColor="#9945FF" />
            <stop offset="100%" stopColor="#e71353" />
          </linearGradient>
        </defs>
      </svg>
    );
  }

  export default CircularProgress;