import youtubeIcon from '../Assets/youTube.png';


function YtButton() {

        const openYoutubeVideo = () => {
          window.open('https://www.youtube.com/watch?v=FOM2WgiomHY', '_blank');
        };

    return(
        <div className="relative group text-lg">
            <div className="absolute -inset-0.5 bg-gradient-to-r from-[#9945FF] to-[#e71353]
             rounded-lg filter blur-md opacity-75 group-hover:opacity-100 transition duration-200 "></div>
             <button onClick={openYoutubeVideo} className="relative px-7 py-2 bg-black rounded-lg leading-none flex items-center divide-x divide-gray-600">
                <span className="flex items-center space-x-4">
                    <img alt="A YT button" src= {youtubeIcon} className="h-7 group-hover:scale-150 transition duration-200"/>
                    <span className="pr-6 text-gray-100">Watch the Demo</span>
                </span>
             </button>
        </div>
    )
}

export default YtButton;